import { DiscountModels } from '@getgreenline/products'
import { computed } from 'mobx'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { POSStore } from '../../stores/POSStore'

export class POSDiscountStore {
  constructor(private currentCompanyStore: CurrentCompanyStore, private posStore: POSStore) {}

  @computed
  private get customerLoyaltyTierId(): number | null {
    return this.posStore.currentPayment?.customer?.loyaltyTierId || null
  }

  private isTierOrPointDiscount = (
    applyType: DiscountModels.ApplyType,
    discount: DiscountModels.IDiscount,
  ): DiscountModels.LoyaltyDiscountType | undefined => {
    const isLoyaltyDiscount =
      discount.applyType === applyType && discount.isLoyalty && discount.loyaltyTierId !== null

    if (!isLoyaltyDiscount || discount.loyaltyTierId !== this.customerLoyaltyTierId) {
      return undefined
    }

    const loyaltyCost = discount.loyaltyCost || 0

    return loyaltyCost === 0
      ? DiscountModels.LoyaltyDiscountType.LOYALTY_TIER
      : DiscountModels.LoyaltyDiscountType.LOYALTY_POINT
  }

  isLoyaltyTierCartDiscount = (discount: DiscountModels.IDiscount): boolean => {
    if (!this.canDiscountWithLoyalty) {
      return false
    }

    return (
      this.isTierOrPointDiscount('cart', discount) ===
      DiscountModels.LoyaltyDiscountType.LOYALTY_TIER
    )
  }

  isLoyaltyPointCartDiscount = (discount: DiscountModels.IDiscount): boolean => {
    const canUseLoyaltyPointsForDiscount = this.currentCompanyStore.loyaltyPointsForDiscounts

    if (!canUseLoyaltyPointsForDiscount || !this.canDiscountWithLoyalty) {
      return false
    }

    return (
      this.isTierOrPointDiscount('cart', discount) ===
      DiscountModels.LoyaltyDiscountType.LOYALTY_POINT
    )
  }

  isLoyaltyTierProductDiscount = (discount: DiscountModels.IDiscount): boolean => {
    if (!this.canDiscountWithLoyalty) {
      return false
    }

    return (
      this.isTierOrPointDiscount('products', discount) ===
      DiscountModels.LoyaltyDiscountType.LOYALTY_TIER
    )
  }

  isLoyaltyPointProductDiscount = (discount: DiscountModels.IDiscount): boolean => {
    if (!this.currentCompanyStore.loyaltyPointsForDiscounts || !this.canDiscountWithLoyalty) {
      return false
    }

    return (
      this.isTierOrPointDiscount('products', discount) ===
      DiscountModels.LoyaltyDiscountType.LOYALTY_POINT
    )
  }

  canApplyLoyaltyTierProductDiscount = (
    availableProductDiscounts: DiscountModels.IDiscount[],
  ): boolean => {
    if (availableProductDiscounts.length === 0) {
      return false
    }

    const matchingDiscount = availableProductDiscounts.filter((discount) => {
      return this.isLoyaltyTierProductDiscount(discount)
    })

    return matchingDiscount.length > 0
  }

  canApplyLoyaltyPointProductDiscount = (
    availableProductDiscounts: DiscountModels.IDiscount[],
  ): boolean => {
    if (
      availableProductDiscounts.length === 0 ||
      !this.currentCompanyStore.loyaltyPointsForDiscounts
    ) {
      return false
    }

    const matchingDiscount = availableProductDiscounts.filter((discount) => {
      return this.isLoyaltyPointProductDiscount(discount)
    })

    return matchingDiscount.length > 0
  }

  // pre-conditions for common loyalty discounts
  @computed
  private get canDiscountWithLoyalty(): boolean {
    return Boolean(
      this.customerLoyaltyTierId !== null &&
        this.currentCompanyStore.company?.permissions.canAccessLoyalty,
    )
  }

  @computed
  get canApplyLoyaltyTierCartDiscount(): boolean {
    const availableCartDiscounts = this.posStore.currentPayment.availableCartDiscounts || []

    if (availableCartDiscounts.length === 0) {
      return false
    }

    const matchingDiscount = availableCartDiscounts.filter((discount) => {
      return this.isLoyaltyTierCartDiscount(discount)
    })

    return matchingDiscount.length > 0
  }

  @computed
  get canApplyLoyaltyPointCartDiscount(): boolean {
    const availableCartDiscounts = this.posStore.currentPayment?.availableCartDiscounts || []

    if (
      availableCartDiscounts.length === 0 ||
      !this.currentCompanyStore.loyaltyPointsForDiscounts
    ) {
      return false
    }

    const matchingDiscount = availableCartDiscounts.filter((discount) => {
      return this.isLoyaltyPointCartDiscount(discount)
    })

    return matchingDiscount.length > 0
  }
}
